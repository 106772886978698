import { render, staticRenderFns } from "./redirectNotFound.vue?vue&type=template&id=f7260dde&scoped=true&"
import script from "./redirectNotFound.ts?vue&type=script&lang=ts&"
export * from "./redirectNotFound.ts?vue&type=script&lang=ts&"
import style0 from "./redirectNotFound.vue?vue&type=style&index=0&id=f7260dde&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7260dde",
  null
  
)

export default component.exports