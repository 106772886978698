import { Component, Vue } from 'vue-property-decorator'
import {} from '@/components/base';
  
@Component({
  components: {},
  beforeRouteEnter(to: any, from: any, next: any) {
    console.log(from);
    // porta 8788 teste local pages do cloudflare
    if(window.location.hostname != 'localhost' || window.location.port == '8788'){
      window.location.replace('/static/404');
    }
    next();
  }
})
export default class RedirectNotFound extends Vue {


  beforeCreate() {
    // console.log(this.$router);
    
    // window.location.href = '/static/404';
    // window.location.replace('/static/404');
  }
  
} 
  